<template>
    <b-modal :id="windowId" title="Test Template">
        <b-container fluid>
            <input-field 
                v-model="reviewer_email"
                title="Rewiew email"
                name="reviewer_email"
                :errors="serverErrors"
            />
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <button type="button" class="btn btn-primary" @click="test">Test</button>
            <b-button @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { randString } from './../../mixins/rand-string.mixin';
import { notificationMixin } from './../../mixins/notification.mixin';
import inputField from './../Form/dnd-admin-input';

export default {
    components: {
        inputField
    },
    mixins: [ randString, notificationMixin ],
    data() {
        return {
            template_id: null,
            reviewer_email: null,
            windowId: this.getRandStr(8),
            serverErrors: []
        }
    },
    methods: {
        show(template_id) {
            this.reviewer_email = null;
            this.template_id = template_id;
            this.serverErrors = null;
            this.$bvModal.show(this.windowId);
        },
        test() {
            const url = 'marketing-email/'+ this.template_id +'/check-sending';
            this.$http.put(url, {
                    'reviewer_email': this.reviewer_email
                })
                .then(() => {
                    this.successNtf("Success!", "Test email sent!");
                    this.$bvModal.hide(this.windowId);
                }, this.responseError);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.serverErrors = res.response.data.errors;
            }
        }
    }
}
</script>
