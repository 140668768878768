<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container" >
            <div class="row mb-4 mt-3">
                <div class="col-4">
                    <router-link v-if="!isSending" class="btn btn-primary" :to="{ name: 'EventCustomers',params: { 'event_id': eventId } }">Back</router-link>
                    <b-button v-if="isSending" variant="primary" disabled>Back</b-button>
                </div>
                <div class="col-8">
                    <b-button v-if="isSending" class="ml-1 float-right" variant="primary" disabled><b-spinner small></b-spinner></b-button>
                    
                    <button v-if="!isSent && !isSending" class="btn btn-primary float-right ml-1" @click="send">Send to {{ getEventCustomersTotal }} {{ getEventCustomersTotal == 1 ? "customer" : "customers" }}</button>
                    <b-button v-if="isSent && !isSending" class="ml-1 float-right" disabled>Send to {{ getEventCustomersTotal }} {{ getEventCustomersTotal == 1 ? "customer" : "customers" }}</b-button>
                    
                    <button v-if="!isSending" class="btn btn-primary float-right" @click="$refs.testTemplatePopup.show(templateId)">Test</button>
                    <b-button v-if="isSending" class="float-right" variant="primary" disabled>Test</b-button>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <h4>Test Parameters</h4>
                     <b-table :small="true" stacked :items="itemsToInsert">
                        <template v-slot:head(youTubeVideoLink)>YouTube Video Link</template>
                        <template v-slot:head(youTubeVideoTitle)>YouTube Video Title</template>
                     </b-table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="template-preview" v-html="markup"></div>
                </div>
            </div>
        </div>
        <br><br><br><br>
        <test-template-popup ref="testTemplatePopup" />
    </div>
</template>
<script>

import navAdmin from './../../components/nav-admin';
import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from './../../mixins/notification.mixin';
import testTemplatePopup from './TestTemplatePopup.vue';

export default {

    mixins: [notificationMixin],
    components: {
        navAdmin,
        testTemplatePopup
    },
    data() {
        return {
            isSending: false
        }
    },
    computed: {
        ...mapGetters(['getTemplate', 'getFieldsToInsertWithMocData', 'getEvent', 'getEventCustomersTotal']),
        eventId() {
            return this.$route.params.event_id;
        },
        isSent() {
            
            if(this.getEvent)
                return this.getEvent.is_sent;
            
            return null;
        },
        templateId() {
            return this.getTemplate.id;
        },
        markup() {
            const strObj = this.itemsToInsert[0];

            let resStr = this.getTemplate.markup;
            
            for(const key in strObj) {
                resStr = resStr.replace("{{" + key + "}}", strObj[key]);
            }

            return resStr;
        },
        itemsToInsert() {
            return [this.getFieldsToInsertWithMocData];
        }
    },
    methods: {
        ...mapActions(['getEventAction', 'sendCompaing']),
        async send() {
            this.isSending = true;
            await this.sendCompaing();
            await this.getEventAction(this.eventId);
            this.successNtf("Success!", "Campaing Sent!");
            this.isSending = false; 
        }
    },
    async mounted() {
        await this.getEventAction(this.eventId);
    }
}
</script>

<style lang="scss" scoped>
.template-preview {
    border: 1px solid;
}
</style>